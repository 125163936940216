import {
  errorNotification,
  successNotification,
} from "../variables/notification";

export const captureKeyframe = async (
  deviceDetail: any,
  publishNode: (args: any) => Promise<any>
): Promise<void> => {
  const deviceName = deviceDetail?.rowId.split("DE#")[1];

  try {
    const response = await publishNode({
      message: JSON.stringify({
        ACTION: "SAVE_IMAGE",
        s3_key: deviceDetail?.deviceData?.keyFrame,
        TARGET: "CAMERA_MANAGER",
        device_id: deviceName,
        ACK: deviceDetail?.node.id,
      }),
      nodeId: deviceDetail?.node.id ?? "",
    });

    if (response.data) {
      successNotification("A new key image is captured");
    }
  } catch (error) {
    errorNotification("Something went wrong, a new key image isn't captured");

    console.error(error);
  }
};

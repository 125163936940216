import { useEffect, useMemo, useState } from "react";

import type { GraphQLResult } from "@aws-amplify/api-graphql";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import BusinessIcon from "@mui/icons-material/Business";
import CheckIcon from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import aws_exports from "../../../../../../../frontend/web/src/aws-exports.json";

import { API, Auth } from "aws-amplify";
import type Observable from "zen-observable-ts";

import { useCreateCustomer } from "../../../hooks/useCreateCustomer";
import useWindowDimensions from "../../../hooks/useWindowDimensions";

import client from "../../../../configs/apolloClient";
import { addDomain } from "../../../../graphql/mutations";
import StyledLoadingButton from "../../../providers/theme/design-tokens/LoadingButton/StyledLoadingButton";
import {
  errorNotification,
  successNotification,
} from "../../../variables/notification";
import { selectedCustomerVariable } from "../../../variables/selectedCustomer";

interface Partner {
  name: string;
  id: string;
}

interface AddCustomerDialogInterface {
  isOpened: boolean;
  closeDialog: () => void;
}

// TODO: improve UI/UX
const AddCustomerDialog = ({
  isOpened = false,
  closeDialog,
}: AddCustomerDialogInterface): JSX.Element => {
  const [customerName, setCustomerName] = useState("");
  const [selectedPartner, setSelectedPartner] = useState("CleanConnect");
  const [partnerList, setPartnerList] = useState<Partner[]>([]);
  const [domains, setDomains] = useState<string[]>([""]);
  const [hasError, setHasError] = useState(false);

  const { width } = useWindowDimensions();

  const [createCustomer, { loading }] = useCreateCustomer();

  // get potential partners to connect with new created customer
  useEffect((): void => {
    setPartnerList([{ name: "CleanConnect", id: "1" }]);
  }, []);

  const apiMutationAddDomain = async (
    domain: string
  ): Promise<GraphQLResult<any> | Observable<object> | undefined> => {
    try {
      const graphqlApiResponse = API.graphql({
        query: addDomain,
        authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
        variables: {
          domain,
          customerId: customerName,
        },
      });

      if (graphqlApiResponse instanceof Promise) {
        await graphqlApiResponse;
      }

      return await graphqlApiResponse;
    } catch (e) {
      console.log(e);
    }
  };

  // eslint-disable-next-line
  // TODO: leave it for now, remove while improving UI/UX
  // const apiMutationAddCustomerAndDomains = async (): Promise<
  //   (GraphQLResult<any> | Observable<object> | undefined)[] | undefined
  // > => {
  //   try {
  //     const graphqlApiResponse = API.graphql({
  //       query: addCustomer,
  //       authToken: (await Auth.currentSession()).getIdToken().getJwtToken(),
  //       variables: {
  //         customerName,
  //         domains,
  //       },
  //     });

  //     if (graphqlApiResponse instanceof Promise) {
  //       const result = await graphqlApiResponse;
  //       console.log(result);
  //     }

  //     // TODO: adjust timing of domain creation for better batching/logic
  //     const domainCreationPromises = Promise.all(
  //       domains.map(
  //         (
  //           domain
  //         ): Promise<GraphQLResult<any> | Observable<object> | undefined> => {
  //           return apiMutationAddDomain(domain);
  //         }
  //       )
  //     );

  //     return await domainCreationPromises;
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };

  const incrementDomainCount = (): void => {
    setDomains((prevDomains): string[] => {
      return [...prevDomains, ""];
    });
  };

  const updateDomains = (index: number, newDomain: string): void => {
    setDomains((prevDomains): string[] => {
      const newDomains = [...prevDomains];

      newDomains[index] = newDomain;

      return newDomains;
    });
  };
  const helperText = useMemo((): string => {
    if (hasError) {
      return "Must include domain extension. Ex: .com, .net, .org";
    }

    return "";
  }, [hasError]);

  const deleteDomain = (domainIndex: number): void => {
    setDomains((prevDomains): string[] => {
      return prevDomains.filter((_domain, index): boolean => {
        return index !== domainIndex;
      });
    });
  };

  const handleDialogClose = (): void => {
    resetStateAndCloseDialog();
  };

  const resetStateAndCloseDialog = (): void => {
    setDomains([""]);

    setCustomerName("");

    // setSelectedPartner("CleanConnect");
    closeDialog();
  };

  const handleConfirmClick = (): void => {
    // Check for valid domains before creating new customer
    for (const domain of domains) {
      if (!domain.includes(".")) {
        setHasError(true);

        return;
      }
    }

    createCustomer({
      customerName,
      // TODO: (get partner ids from DB); currently just add admin group id from session token
      domains,
      identityPoolId: aws_exports.aws_cognito_identity_pool_id,
      userPoolId: aws_exports.aws_user_pools_id,
      bucketName: aws_exports.aws_node_files_s3_bucket,
    })
      .then(async (response): Promise<void> => {
        if (response.data?.addCustomer?.id) {
          await client.cache.reset();

          selectedCustomerVariable(response.data.addCustomer.id);

          localStorage.setItem(
            "customer",
            JSON.stringify({
              title: response.data.addCustomer.name,
              value: response.data.addCustomer.id,
            })
          );

          const domainCreationPromises = Promise.all(
            // eslint-disable-next-line
            domains.map(domain => apiMutationAddDomain(domain))
          );

          await domainCreationPromises.then((): void => {
            resetStateAndCloseDialog();

            successNotification("Customer has been successfully created!");
          });
        }
      })
      .catch((error): void => {
        errorNotification();

        console.error(error);
      });
  };

  return (
    <Dialog
      open={isOpened}
      onClose={handleDialogClose}
      fullWidth
      maxWidth="sm"
      sx={{ boxShadow: 3 }}
    >
      <Box>
        <DialogTitle
          sx={{
            textAlign: "center",
            alignItems: "center",
          }}
        >
          <BusinessIcon />
          <div>Register a new Customer company</div>
        </DialogTitle>
        <Divider />
        {/* TODO: also adjust styling to use grid instead of margins */}
        <DialogContent>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 10,
            }}
          >
            <Typography variant="body1" gutterBottom>
              Customer Name:
            </Typography>
            <div>
              <TextField
                required
                id="customer-name"
                label="customer"
                value={customerName}
                onChange={(e): void => setCustomerName(e.target.value)}
              />
            </div>
          </Box>
          <Box
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              padding: 10,
            }}
          >
            <Typography variant="body1" gutterBottom>
              Partner:
            </Typography>
            {/* todo: replace magic number enum with grid system */}
            <FormControl fullWidth style={{ width: width * 0.2 }}>
              <InputLabel required id="partner-select-label">
                partner
              </InputLabel>

              <Select
                labelId="partner-select-label"
                id="partner-select"
                value={selectedPartner}
                label="Partner"
                onChange={(e): void => {
                  setSelectedPartner(e.target.value);
                }}
              >
                {partnerList.map((partner): JSX.Element => {
                  return (
                    <MenuItem key={partner.id} value={partner.name}>
                      {partner.name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Box>

          {/* no stable id with domains therefore key defaulting to index */}
          {domains.map((domain, index): JSX.Element => {
            return (
              <Box
                key={index}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  padding: 10,
                }}
              >
                <Typography variant="body1" gutterBottom>
                  Accepted Domain(s):
                </Typography>
                {/* if there is more than one domain, allow option to delete additional domain(s) */}
                {domains.length > 1 && (
                  <DeleteForeverIcon
                    style={{ cursor: "pointer" }}
                    onClick={(): void => deleteDomain(index)}
                  />
                )}
                <div>
                  <TextField
                    key={index}
                    error={hasError}
                    helperText={helperText}
                    required
                    label="domain"
                    value={domains[index]}
                    onChange={(e): void => {
                      updateDomains(index, e.target.value);
                    }}
                  />
                </div>
              </Box>
            );
          })}
        </DialogContent>
      </Box>
      {domains.length <= 2 && (
        <>
          <Typography style={{ textAlign: "center" }}>
            Add another domain
          </Typography>

          <div
            style={{
              display: "flex",
              alignItems: "center",
              flexDirection: "column",
              cursor: "pointer",
            }}
          >
            <AddCircleIcon onClick={incrementDomainCount} />
          </div>
        </>
      )}
      <DialogActions
        sx={{
          display: "flex",
          direction: "row",
          justifyContent: "center",
          padding: "2em",
        }}
      >
        <StyledLoadingButton
          sx={{ width: "30%" }}
          loading={loading}
          loadingPosition="start"
          startIcon={<CheckIcon />}
          variant="contained"
          color="success"
          onClick={handleConfirmClick}
        >
          Confirm
        </StyledLoadingButton>
        <Button
          sx={{ width: "30%" }}
          variant="outlined"
          color="error"
          startIcon={<Close />}
          onClick={resetStateAndCloseDialog}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddCustomerDialog;

/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listenToAddZone = /* GraphQL */ `
  subscription ListenToAddZone($customerId: String) {
    listenToAddZone(customerId: $customerId) {
      alertData
      deviceId
      exclusions
      id
      inspectTime
      keyFrame
      name
      nodeId
      pan
      serviceId
      status
      threshold {
        id
        name
        value
      }
      tilt
      zoom
    }
  }
`;
export const listenToDeleteZone = /* GraphQL */ `
  subscription ListenToDeleteZone($id: String) {
    listenToDeleteZone(id: $id) {
      alertData
      deviceId
      exclusions
      id
      inspectTime
      keyFrame
      name
      nodeId
      pan
      serviceId
      status
      threshold {
        id
        name
        value
      }
      tilt
      zoom
    }
  }
`;
export const listenToDeviceHealthData = /* GraphQL */ `
  subscription ListenToDeviceHealthData($customerId: String!) {
    listenToDeviceHealthData(customerId: $customerId) {
      cameraIpAddress
      customerId
      deviceData
      healthData
      id
      locationId
      locationName
      makeModelId
      name
      nodeId
      nodeName
      status
    }
  }
`;
export const listenToEdgeClientMessage = /* GraphQL */ `
  subscription ListenToEdgeClientMessage($id: String) {
    listenToEdgeClientMessage(id: $id) {
      id
      payload
      payloadType
      statusCode
      timestamp
      topic
    }
  }
`;
export const listenToHumanValidationEvents = /* GraphQL */ `
  subscription ListenToHumanValidationEvents {
    listenToHumanValidationEvents {
      audited_by
      auditorsExplanation
      customerId
      data
      eventTime
      explanation
      id
      locationId
      nodeId
      serviceId
      transitionInfo
      validated_by
      validationState
    }
  }
`;
export const listenToNodeFiles = /* GraphQL */ `
  subscription ListenToNodeFiles($nodeId: String!) {
    listenToNodeFiles(nodeId: $nodeId) {
      downloadURL
      file
      internalNodeId
    }
  }
`;
export const listenToNodeHealthData = /* GraphQL */ `
  subscription ListenToNodeHealthData($customerId: String!) {
    listenToNodeHealthData(customerId: $customerId) {
      awsHealth
      customerId
      deviceData
      devices {
        cameraIpAddress
        customerId
        deviceData
        healthData
        id
        locationId
        locationName
        makeModelId
        name
        nodeId
        nodeName
        status
      }
      healthData
      id
      level
      locationId
      nodeIP
      nodeId
      nodeName
      onboardCommand
      referenceId
      serviceData
      tags {
        id
        tagName
      }
    }
  }
`;
export const listenToNodeMessages = /* GraphQL */ `
  subscription ListenToNodeMessages($nodeId: String!) {
    listenToNodeMessages(nodeId: $nodeId) {
      internalNodeId
      message
    }
  }
`;
export const listenToServiceHealthData = /* GraphQL */ `
  subscription ListenToServiceHealthData($customerId: String!) {
    listenToServiceHealthData(customerId: $customerId) {
      configuration
      customerId
      deviceId
      healthData
      id
      isRunning
      locationId
      nodeId
      serviceType
    }
  }
`;
export const listenToUpdateZone = /* GraphQL */ `
  subscription ListenToUpdateZone($id: String) {
    listenToUpdateZone(id: $id) {
      alertData
      deviceId
      exclusions
      id
      inspectTime
      keyFrame
      name
      nodeId
      pan
      serviceId
      status
      threshold {
        id
        name
        value
      }
      tilt
      zoom
    }
  }
`;
